
              @import "@/assets/css/variables.scss";
            






























































































































































































































































































































































































































































// @import '@/assets/css/pages/rebateReport.scss';
.center_card {
  .center_card_title {
    display: flex;
    align-items: center;
    color: $primary;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    .title_icon {
      width: 24px;
    }
    .title_text {
      font-size: 16px;
      color: $text-primary;
      padding: 0 8px;
    }
    .curr_text {
      @include rtl-sass-prop(padding-right, padding-left, 5px);
    }
  }
  .card_list {
    margin-bottom: -16px;
    .card_list_item {
      padding: 16px;
      border-radius: 8px;
      background-color: $background-color-base;
      margin-bottom: 16px;
      .item_title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .title_icon {
          width: 24px;
          @include rtl-sass-prop(margin-right, margin-left, 8px);
        }
        .title_text {
          font-size: 14px;
          color: $text-primary;
          text-transform: uppercase;
        }
      }
      .item_content {
        margin-bottom: 16px;
        .content_label {
          color: #6e6e73;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 8px;
        }
        .content_value {
          font-size: 14px;
          line-height: 22px;
          color: $text-primary;
          font-weight: 700;
          .curr_text {
            @include rtl-sass-prop(padding-right, padding-left, 5px);
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.table_box {
  .table_title {
    font-size: 16px;
    font-weight: 700;
    color: $text-primary;
    margin-bottom: 16px;
  }
}
