.el-dialog__body {
  // padding: 0 55px 45px 50px;

  .title {
    margin-bottom: 10px;

    span {
      font-size: 15px;
      @include rtl-sass-value(text-align, left, right);
      line-height: 2;
      color: $text-secondary;

      &.blue {
        color: $primary;
      }

      &.row {
        display: block;
      }
    }
  }

  .info {
    margin-bottom: 25px;

    ul {
      display: grid;
      grid-template-columns: 50% 50%;

      li {
        font-size: 15px;
        text-transform: uppercase;
        display: inline-block;

        span {
          color: $text-secondary;
        }

        p {
          color: $primary;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .echarts {
    width: 100%;
    height: 275px;
    padding: 20px 0;
    margin-bottom: 35px;
    background-color: $background-color-base;
  }

  .table-content {
    // /deep/ .el-collapse-item__header {
    //   .el-collapse-item__arrow {
    //     display: none;
    //   }

    //   &.is-active {
    //     .title p img {
    //       transform: rotate(0deg);
    //     }
    //   }
    // }

    // .title {
    //   p {
    //     line-height: 1;
    //     margin-bottom: 10px;

    //     span {
    //       font-size: 15px;
    //       color: $bottle-blue;
    //       line-height: 2;
    //       @include rtl-sass-prop(padding-right, padding-left, 10px);
    //       display: inline-block;
    //     }

    //     img {
    //       display: inline-block;
    //       transform: rotate(270deg);
    //     }
    //   }
    // }

    // table表格
    .table-box {
      width: 100%;
      height: 391px;
      margin-bottom: 0;

      // .el-table {
      //   line-height: 51px;
      // }
    }
  }
}

// @media (max-width: 767px) {
//   /deep/ .el-dialog__body {
//     padding: 0 20px 20px;
//   }
// }
